import React from "react";

const about = () => {
  return (
    <div>
      <section id="about">
        <div className="container">
          <div className="row">
            <h2 className="brand">About Us</h2>
            <div className="col-lg-12 col-md-12 col-sm-12">
              <p>
                Alteco Ventures is a leading Manufacturer, Exporter, Importer,
                and Supplier of high-quality industrial minerals. We specialize
                in supplying a diverse range of products, including Mica Powder,
                Calcium Carbonate Powder, China Clay Powder, Calcite Powder,
                Feldspar Powder, Quartz, Kaolin Powder, Wollastonite Powder,
                Paper Grade Talc Powder, and Dolomite Powder. With a
                state-of-the-art production facility and a robust global supply
                network, we are equipped to handle large-scale international
                orders while ensuring quality, consistency, and timely delivery.
              </p>
              <p>
                Our Presence Founded in 2018, Alteco Ventures is headquartered
                in Mumbai, India, with advanced manufacturing and processing
                facilities in Udaipur, Rajasthan—a region renowned for its rich
                mineral resources. Under the leadership of Mr. Taher Thanawala,
                we have built a strong presence across Asia, the Middle East,
                Europe, and North America, catering to diverse industry needs.
                Commitment to Quality We adhere to strict international quality
                standards, ensuring that our minerals meet the technical and
                performance requirements of industries such as plastics,
                ceramics, paints, coatings, paper, and construction. Our
                dedicated quality control team conducts rigorous testing at
                every stage of production to guarantee purity, consistency, and
                superior performance.
              </p>
              <p>
                Custom Packaging & Logistics To maintain the integrity of our
                products, we use hygienic, non-toxic, and industry-approved
                packaging materials. We also offer customized packaging
                solutions tailored to the specific needs of our international
                buyers. With a well-established logistics network, we ensure
                efficient and hassle-free global shipping.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section id="value">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="screen">
                <h4 className="head">Our Value</h4>
                <p className="underline"></p>
                <p className="screen-para">
                  At Alteco Ventures, we uphold excellence, integrity, and
                  customer satisfaction. We ensure quality through rigorous
                  testing, precision manufacturing, and transparency in all
                  dealings. Innovation and sustainability drive our eco-friendly
                  processes, refining products for better efficiency. With
                  ethical business practices and a strong logistics network, we
                  ensure seamless global distribution. Our commitment to
                  reliability, efficiency, and trust makes us a valued partner
                  in industrial growth.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="screen">
                <h4 className="head">Our Vision</h4>
                <p className="underline"></p>
                <p className="screen-para">
                  We strive to be a global leader in premium industrial
                  minerals, setting benchmarks in quality, innovation, and
                  sustainability. Through research and technology, we empower
                  industries with high-performance solutions. Strong
                  international partnerships and ethical business practices help
                  us exceed industry standards. Our goal is to enhance product
                  efficiency while preserving natural resources. By prioritizing
                  customer needs, we aim to be the preferred choice in multiple
                  industries.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="screen">
                <h4 className="head">Our Mission</h4>
                <p className="underline"></p>
                <p className="screen-para">
                  Our mission is to deliver innovative, high-quality, and
                  sustainable mineral solutions that drive industrial progress.
                  We ensure excellence through precision manufacturing, strict
                  quality control, and eco-friendly practices. By fostering
                  long-term partnerships and leveraging a strong supply network,
                  we provide reliable solutions. Our focus on efficiency,
                  transparency, and sustainability allows us to set new industry
                  benchmarks while preserving natural resources.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="why">
        <div className="container">
          <div className="row">
            <h3 className="center head">Why Partner with Alteco Ventures?</h3>
            <div class="col-lg-4 col-md-6 col-sm-12 center">
              <i
                class="fa fa-thumbs-up"
                style={{
                  fontSize: "3em",
                  textAlign: "center",
                  animation: "rotate 2s linear",
                }}
              ></i>
              <h5 class="small-head">Premium Quality Minerals</h5>
              <p class="small-para">
                Sourced and processed to meet global standards.
              </p>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 center">
              <i
                class="fa fa-clock-o"
                style={{
                  fontSize: "3em",
                  textAlign: "center",
                  animation: "rotate 2s linear",
                }}
              ></i>
              <h5 class="small-head">Reliable & On-Time Delivery</h5>
              <p class="small-para">Streamlined supply chain management.</p>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 center">
              <i
                class="fa fa-industry"
                style={{
                  fontSize: "3em",
                  textAlign: "center",
                  animation: "rotate 2s linear",
                }}
              ></i>
              <h5 class="small-head">Industry-Specific Solutions</h5>
              <p class="small-para">Customized mineral formulations.</p>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 center">
              <i
                class="fa fa-shield"
                style={{
                  fontSize: "3em",
                  textAlign: "center",
                  animation: "rotate 2s linear",
                }}
              ></i>
              <h5 class="small-head">Secure & Transparent Transactions</h5>
              <p class="small-para">
                Hassle-free international trade practices
              </p>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 center">
              <i
                class="fa fa-money"
                style={{
                  fontSize: "3em",
                  textAlign: "center",
                  animation: "rotate 2s linear",
                }}
              ></i>
              <h5 class="small-head">Competitive Pricing</h5>
              <p class="small-para">
                High-value products at cost-effective rates.
              </p>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 center">
              <i
                class="fa fa-user-circle"
                style={{
                  fontSize: "3em",
                  textAlign: "center",
                  animation: "rotate 2s linear",
                }}
              ></i>
              <h5 class="small-head">Dedicated Customer Support</h5>
              <p class="small-para">Quick response to inquiries and orders.</p>
            </div>
            {/* div */}
            <p className="center small-head">
              At Alteco Ventures, we are committed to fostering long-term
              partnerships by delivering top-quality minerals, reliable service,
              and tailored solutions to businesses worldwide.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default about;
