import React from "react";

const contact = () => {
  return (
    <div>
      {/* contact */}
      <section id="contact">
        <div className="container">
          <div className="row">
            <h4 className="cnt-head">Contact Us</h4>
            <p className="cnt-para">
              Connect with us today to explore how our minerals can enhance your
              business!
            </p>
            <div className="col-lg-4 col-md-4 col-sm-12 contact-data">
              <i className="fa fa-map-marker" style={{ fontSize: "30px" }}></i>
              <p className="add-para">
                710, Fenkin9, Near Satkar Grande, Wagle Estate, Thane West - 400
                604.
              </p>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12 contact-data">
              <i className="fa fa-phone" style={{ fontSize: "30px" }}></i>
              <p className="add-para">+91 7738156675</p>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12">
              <i className="fa fa-envelope" style={{ fontSize: "30px" }}></i>
              <p className="add-para">sales@tigiindustries.in</p>
            </div>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3768.1750985194694!2d72.95129117498035!3d19.187553082040125!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b94a35a1826b%3A0x238d801433eaeae4!2sFenkin9!5e0!3m2!1sen!2sin!4v1730219855990!5m2!1sen!2sin"
              width="600"
              height="450"
              style={{ border: "0" }}
              allowFullScreen
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              title="Google Maps Location of Fenkin9" // Added title attribute
            ></iframe>
          </div>
        </div>
      </section>
      {/* contact-end */}
    </div>
  );
};

export default contact;
